export const metaData = {
  'meta-title': '404',
  'meta-description': "You've reached this page unjustly",
};

export const body = {
  header: 'Lost in 404 territory?',
  description:
    'Whether you’re exploring new financial terrain, or just have a typo in the URL, let’s get you back on track.',
  button: 'Go home',
};
