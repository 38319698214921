import {
  defaultCRAFormFields,
  defaultCRAFormIntro,
} from './cra-default-translations';

export const translationsData = {
  en: {
    metaData: {
      layout: 'docs',
      noIndex: true,
      'meta-title': 'Forgot PIN?',
      'meta-description': 'Forgot PIN?',
      'hide-search': 'true',
      'subnav-filters-hidden': 'true',
    },
    craEntityName: 'Plaid Check',
    pageHeader: 'Forgot PIN?',
    requestType: 'Forgot PIN?',
    introHeader: 'Introduction',
    intro: `<p>Complete this form if you have forgotten or misplaced the Personal Identification Number (PIN) provided to you when placing a security freeze. This PIN is required to remove the freeze from your Plaid Check consumer file.</p>
            <p>${defaultCRAFormIntro}</p>`,
    ...defaultCRAFormFields,
  },
};
