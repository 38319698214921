export const metaData = {
  layout: 'demo',
  'meta-title': 'Plaid Demo',
  'meta-description':
    'Explore how Plaid Link helps 100+ million people to securely connect their financial accounts to thousands of apps.',
  '': null,
};

export const pageHeaderData = {
  status: 'Success!',
  subtext:
    'You have completed Plaid Link. Your credentials were verified and used to retrieve the account data shown.',
};

export const pageHeaderIVData = {
  status: 'Success!',
  subtext:
    'You have completed Plaid Link. Sample payroll data and verified information shown.',
};

export const pageHeaderBIData = {
  status: 'Success!',
  subtext: 'You have completed Plaid Link. Sample bank income data shown.',
};

export const pageHeaderIdentityData = {
  status: 'Success!',
  subtext: 'You have completed Plaid Link. Sample bank identity data shown.',
};

export const pageHeaderAuthData = {
  status: 'Success!',
  subtext:
    'You have completed Plaid Link. Sample bank account and routing number data shown.',
};

export const pageHeaderPIData = {
  status: 'Success!',
  subtext: 'You have completed Plaid Link. Sample payment information shown.',
};

export const pageHeaderMmdInitiationData = {
  status: 'Account Verification Pending',
  subtext: 'You have initiated microdeposits. Click below to verify account.',
};

export const cardSectionData = {
  'header-section': true,
  'card-count': 'two',
  'header-text': 'MORE ABOUT LINK',
  'header-subtext': 'How did this work?',
  cards: [
    {
      'section-header-text': 'Behind the scenes',
      'section-link': 'https://github.com/plaid/quickstart',
      'section-text':
        "You completed the Plaid Link onboarding. Link is a pre-built interface that interacts with Plaid's API servers to take you through a streamlined, fully-encrypted onboarding process.<br><br>Once you successfully entered valid credentials, Link verified them and made a request to the Plaid API to retrieve the account data above. For more information on how this works technically, see the documentation below.",
      'section-cta-text': 'View our Quickstart',
    },
    {
      'section-header-text': 'Exchange token flow',
      'section-link':
        'https://plaid.com/docs/quickstart/#creating-items-with-link-and-the-api',
      'section-text':
        'Plaid Link returns a public_token for a given user, which can be exchanged for an access_token using the Exchange Token endpoint.<br><br>In this example, we pull the public_token from the query string and send it to the app server. The app server will make two Plaid API requests using your private client_id and secret. The first will exchange the public_token for a Plaid access_token. The second will use that access_token to retrieve account information, including account names and current balances.',
      'section-cta-text': 'View our Documentation',
    },
  ],
};
